<template>
  <div v-if="property" id="property-detail">
    <CRow class="title pb-2">
      <CCol col="auto pl-0">
        <h2>
          {{ property.property.name }}
        </h2>
      </CCol>
      <CCol col="auto pr-0">
        <RWidgetStatus :status="property.property.status" />
      </CCol>
    </CRow>
    <CAlert v-if="isInactive" color="warning" class="mt-2 mb-0">
      This property does not currently have any active subscriptions. To resume service, please
      <CButton class="btn-link" @click="showReactivationModal = true">
        submit a re-activation request.
      </CButton>
    </CAlert>
    <div class="property-detail__content">
      <div v-if="isInactive" class="inactive-overlay" />
      <CRow class="pt-4">
        <CCol
          md="12"
          lg="6"
          class="mb-4"
        >
          <CCard class="property-info">
            <CCardHeader>
              Property Information
              <RChangeRequestButton :before-open="setContactSupportFlag" />
            </CCardHeader>
            <CCardBody>
              <RWidgetInfo icon="md-phone" label="Phone" :value="property.property.phone_contact" />
              <RWidgetInfo icon="md-computer" label="Website">
                <div v-if="property.property.floor_plan_urls.length > 0">
                  <a
                    v-for="url in property.property.floor_plan_urls"
                    :key="url"
                    :href="url"
                    target="_blank"
                  >
                    {{ url }}
                  </a>
                </div>
              </RWidgetInfo>
              <RWidgetInfo icon="md-email" label="Email" :value="property.property.email_contact" />
              <RWidgetInfo icon="md-email" label="Property Manager Email" :value="property.property.email_manager" />
              <RWidgetInfo icon="md-place" label="Address" :value="propertyAddress" />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="12" lg="6">
          <CRow class="mb-4">
            <CCol>
              <CCard>
                <CCardHeader>
                  Software Details
                </CCardHeader>
                <CCardBody>
                  <CRow
                    align-horizontal="between"
                    align-vertical="center"
                    class="software-details mb-3"
                  >
                    <CCol col="auto">
                      <h5 class="header">
                        Installation Link
                      </h5>
                    </CCol>
                    <CCol col="auto">
                      <CDropdown
                        color="info"
                        :split="true"
                        add-toggler-classes="toggler"
                      >
                        <template #toggler-content>
                          <a :href="pe4dLink" target="_blank" @click="mixpanelTrack('PE4D Install Link Click')">
                            PostEngine for Desktop
                          </a>
                        </template>
                        <CDropdownItem :href="pe4cLink" target="_blank" @click="mixpanelTrack('PE4C Install Link Click')">
                          PostEngine for Chrome
                        </CDropdownItem>
                      </CDropdown>
                    </CCol>
                  </CRow>
                  <CRow
                    align-horizontal="between"
                    align-vertical="center"
                    class="software-details"
                  >
                    <CCol col="auto">
                      <h5 class="header">
                        Activation Code
                      </h5>
                      <span id="activation-code">
                        {{ property.property.activation_code }}
                      </span>
                    </CCol>
                    <CCol col="auto">
                      <CButton color="info" @click="copyActivationCode">
                        {{ copyButtonText }}
                      </CButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="mb-4">
            <CCol>
              <CCard>
                <CCardHeader>
                  Craigslist Credentials
                  <RChangeRequestButton :before-open="setContactSupportFlag" />
                </CCardHeader>
                <CCardBody>
                  <CRow
                    align-horizontal="between"
                    align-vertical="center"
                    class="cl-credentials mb-3"
                  >
                    <CCol col="auto">
                      <h5 class="header">
                        Username
                      </h5>
                      <span id="cl-username">
                        {{ property.active_account || '(none)' }}
                      </span>
                    </CCol>
                    <CCol col="auto">
                      <CButton
                        v-if="property.active_account"
                        class="btn-icon"
                        shape="pill"
                        @click="copyClUsername"
                      >
                        <CIcon name="md-content-copy" />
                      </CButton>
                    </CCol>
                  </CRow>
                  <CRow
                    align-horizontal="between"
                    align-vertical="center"
                    class="cl-credentials"
                  >
                    <CCol col="auto">
                      <h5 class="header">
                        Password
                      </h5>
                      <span>
                        {{ property.has_password ? clPassword : '(none)' }}
                      </span>
                    </CCol>
                    <CCol v-if="property.has_password" col="auto">
                      <CButton class="btn-icon mr-2" shape="pill" @click="togglePasswordVisibility">
                        <CIcon :name="passwordVisible ? 'md-visibility-off' : 'md-visibility'" />
                      </CButton>
                      <CButton class="btn-icon" shape="pill" @click="copyClPassword">
                        <CIcon name="md-content-copy" />
                      </CButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
        <CCol
          md="12"
          lg="6"
          class="mb-4"
        >
          <CCard>
            <CCardHeader>
              Tracking Information
              <RChangeRequestButton :before-open="setContactSupportFlag" />
            </CCardHeader>
            <CCardBody>
              <RWidgetInfo icon="md-phone" label="Tracking Number" :class="{ 'locked': !property.property.phone_leasing }">
                <span v-if="property.property.phone_leasing">
                  <a :href="`tel:${property.property.phone_leasing}`">
                    {{ property.property.phone_leasing }}
                  </a>
                </span>
                <span v-else>
                  Contact <a href="mailto:help@rooof.com" target="_blank">help@rooof.com</a> to add this feature.
                </span>
              </RWidgetInfo>
              <RWidgetInfo icon="md-email" label="Tracking Email" :class="{ 'locked': !property.property.email_delivery_lead }">
                <span v-if="property.property.email_delivery_lead">
                  <a :href="`mailto:${property.property.email_delivery_lead}`" target="_blank">
                    {{ property.property.email_delivery_lead }}
                  </a>
                </span>
                <span v-else>
                  Contact <a href="mailto:help@rooof.com" target="_blank">help@rooof.com</a> to add this feature.
                </span>
              </RWidgetInfo>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol
          md="12"
          lg="6"
          class="mb-4"
        >
          <CCard>
            <CCardHeader>
              Billing Information
              <RChangeRequestButton :before-open="setContactSupportFlag" />
            </CCardHeader>
            <CCardBody>
              <RWidgetInfo icon="md-place" label="Billing Address" :value="billingAddress" />
              <RWidgetInfo icon="md-email" label="Billing Email" :value="property.property.billing_email" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow class="mb-4">
        <CCol>
          <CCard>
            <CCardHeader>
              Subscriptions
              <RChangeRequestButton :before-open="setContactSupportFlag" />
            </CCardHeader>
            <CCardBody>
              <SubscriptionTable :property="property" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <PropertyReactivationModal
      :show="showReactivationModal"
      :property="property.property"
      @close="showReactivationModal = false"
    />
  </div>
</template>

<script>
import Mixpanel from 'mixpanel-browser'

import { RChangeRequestButton } from '@/components/buttons'
import { RWidgetInfo, RWidgetStatus } from '@/components/widgets'
import { CraigslistAPI } from '@/services/api/resources'
import { copyToClipboard, formatAddress } from '@/utils'
import constants from '@/utils/constants'

import PropertyReactivationModal from '../_components/PropertyReactivationModal'
import SubscriptionTable from './SubscriptionTable'

export default {
  name: 'PropertyDetail',
  components: {
    PropertyReactivationModal,
    SubscriptionTable,
    RChangeRequestButton,
    RWidgetStatus,
    RWidgetInfo
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      copyButtonText: 'Copy Code',
      showReactivationModal: false,
      pe4dLink: 'https://www.postengine.com/install-desktop',
      pe4cLink: 'https://chrome.google.com/webstore/detail/postengine-by-rooof/oonoojbjhcfdijfcnibboaapchbbmipl',
      clPassword: '**************',
      passwordVisible: false
    }
  },
  computed: {
    propertyAddress () {
      return formatAddress(this.property.property.property_address)
    },
    billingAddress () {
      return formatAddress(this.property.property.billing_address)
    },
    isInactive () {
      return this.property.property.status === constants.rooof.Status.INACTIVE
    }
  },
  methods: {
    /**
     * Copy the activation code to clipboard.
     */
    copyActivationCode () {
      this.mixpanelTrack('Copied Activation Code')
      copyToClipboard('activation-code')
      this.copyButtonText = 'Copied!'
      setTimeout(() => {
        this.copyButtonText = 'Copy Code'
      }, 1200)
    },
    /**
     * Copy the CL username to clipboard.
     */
    copyClUsername () {
      copyToClipboard('cl-username')
      this.$store.commit('notification/show', {
        message: 'Copied!',
        type: 'success'
      })
    },
    /**
     * Copy the CL password to clipboard.
     */
    async copyClPassword () {
      const account = await this.fetchCraigslistAccount(this.property.active_account)
      await navigator.clipboard.writeText(account.password)
      this.$store.commit('notification/show', {
        message: 'Copied!',
        type: 'success'
      })
    },
    /**
     * Hides/Shows the Craigslist Password
     */
    async togglePasswordVisibility () {
      if (this.passwordVisible) {
        this.clPassword = '**************'
      } else {
        const account = await this.fetchCraigslistAccount(this.property.active_account)
        this.clPassword = account.password
      }
      this.passwordVisible = !this.passwordVisible
    },
    /**
     * Fetches the Craigslist Account from the API
     *
     * @param {String} email
     */
    async fetchCraigslistAccount (email) {
      const response = await CraigslistAPI.accounts.retrieve({ id: email })
      return response && response.data
    },
    /**
     * Sets the "My company/property information is incorrect" checkbox
     * in the Contact Support modal.
     */
    setContactSupportFlag () {
      this.$store.commit('support/set', ['incorrect_company_property_info', true])
    },
    /**
     * Sends tracking data to mixpanel
     *
     * @param {String} eventName - name of mixpanel event
     */
    mixpanelTrack (eventName) {
      if (process.env.NODE_ENV === 'production' && !this.$store.getters['auth/isStaff']) {
        Mixpanel.track(eventName, {
          Company: this.company.human_name,
          Property: this.property.property.name
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin: auto;
  border-bottom: 2px solid $gray-100;
  justify-content: space-between;
  align-items: center;
}
.status-widget {
  font-weight: bold;
  font-size: 1rem;
}
.btn-link {
  color: inherit;
  text-decoration: underline;
  font-weight: 700;
  padding: 0.1em 0 0.2em !important;
}
.btn-icon:hover {
  background-color: $gray-50;
}
.property-detail__content {
  position: relative;

  .inactive-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: $white;
    opacity: 0.6;
  }
  .card-header {
    font-weight: 800;
    font-size: 1.1rem;
    display: flex;
    justify-content: space-between;
  }
  .card {
    height: 100%;
    .card-body {
      padding-bottom: 0;
      .r-widget-info:last-of-type {
        padding-bottom: 0 !important;
      }
      ::v-deep .locked {
        .label, .c-icon {
          color: $gray-500;
        }
      }
      .software-details, .cl-credentials {
        font-weight: 500;
        .header {
          font-weight: 700;
          margin-bottom: 0.25em;
        }
        ::v-deep .toggler:not(.dropdown-toggle) {
          padding: 0 !important;
          a {
            padding: 0.25em 0.75em;
            color: inherit;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
</style>
