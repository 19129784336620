<template>
  <div>
    <RDataTable :items="subscriptions" :fields="columns" :table-props="tableProps">
      <template #status="{ item }">
        <td>
          <RWidgetStatus :status="item.status" />
        </td>
      </template>
      <template #start_date="{ item }">
        <td>
          {{ formatDateString(item.start_date) }}
        </td>
      </template>
      <template #end_date="{ item }">
        <td>
          {{ formatDateString(item.end_date) }}
        </td>
      </template>
      <template #rate="{ item }">
        <td>
          ${{ getRateValue(item) }}
        </td>
      </template>
      <template #discount="{ item }">
        <td>
          {{ getDiscountValue(item) }}
        </td>
      </template>
      <template #total="{ item }">
        <td>
          ${{ getTotalValue(item) }}
        </td>
      </template>
    </RDataTable>
  </div>
</template>

<script>
import { format } from 'date-fns'

import { RDataTable } from '@/components/tables'
import { RWidgetStatus } from '@/components/widgets'
import { formatDate } from '@/utils'
import constants from '@/utils/constants'

export default {
  name: 'SubscriptionTable',
  components: {
    RDataTable,
    RWidgetStatus
  },
  props: {
    property: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      tableProps: {
        sorter: false
      },
      billingFrequency: constants.rooof.BillingFrequency.getAsObject({ inverted: true })
    }
  },
  computed: {
    columns () {
      const columns = [
        { key: 'name', _style: 'width: 20%' },
        { key: 'type', _style: 'width: 10%' },
        { key: 'status', _style: 'width: 15%' },
        { key: 'start_date', _style: 'width: 15%' },
        { key: 'end_date', _style: 'width: 15%' },
        { key: 'rate', _style: 'width: 10%' },
        { key: 'total', _style: 'width: 15%' }
      ]
      if (this.showDiscount) {
        columns.splice(6, 0, { key: 'discount', _style: 'width: 10%' })
      }
      return columns
    },
    subscriptions () {
      if (this.property === null) {
        return []
      }
      const productSubscriptions = this.property.property.product_subscriptions.filter(
        el => el.status !== constants.rooof.Status.INACTIVE &&
        el.status !== constants.rooof.Status.INVALID).map(el => {
        el.name = el.product
        el.type = 'Product'
        return el
      })
      const featureSubscriptions = this.property.property.feature_subscriptions.filter(
        el => el.status !== constants.rooof.Status.INACTIVE &&
        el.status !== constants.rooof.Status.INVALID).map(el => {
        el.name = el.feature
        el.type = 'Add-on'
        return el
      })
      return productSubscriptions.concat(featureSubscriptions)
    },
    showDiscount () {
      // Prevent rendering the `discount` column if no subscriptions have a valid discount
      return this.subscriptions.some(el => {
        return this.hasDiscount(el)
      })
    }
  },
  methods: {
    /**
     * Format an APIv2 date fields.
     *
     * @param {(String|null)} date
     * @returns {String}
     */
    formatDateString (date) {
      if (!date) {
        return ''
      }
      return formatDate(date, 'MMM dd, yyyy')
    },
    /**
     * Determine if Product/Feature has an active discount.
     *
     * @param {Object} row
     * @returns {Boolean}
     */
    hasDiscount (row) {
      const hasDiscount = Boolean(row.discount)
      const expires = row.discount_expires

      if (hasDiscount && expires) {
        const today = format(new Date(), 'yyyy-MM-dd')
        return expires >= today
      }
      return hasDiscount
    },
    /**
     * Get the value to display in `rate` table column.
     *
     * @param {Object} row
     * @returns {String|Number}
     */
    getRateValue (row) {
      const rate = parseFloat(row.rate).toFixed(2)
      return row.low_density ? `${rate} (low-density)` : rate
    },
    /**
     * Get the value to display in `discount` table column.
     *
     * @param {Object} row
     * @returns {String}
     */
    getDiscountValue (row) {
      if (!row.discount) {
        return '(none)'
      }
      switch (row.discount_type) {
        case '$':
          return '$' + row.discount
        case '%':
          return row.discount + '%'
      }
    },
    /**
     * Calculate the total (rate - discount), including
     * the billing frequency.
     *
     * If the subscription is per-unit, use the unit
     * count to determine the rate.
     *
     * @param {Object} row
     * @returns {String}
     */
    getTotalValue (row) {
      let total = parseFloat(row.rate)
      let discount = parseFloat(row.discount)

      if (this.hasDiscount(row)) {
        switch (row.discount_type) {
          case '$': {
            total -= discount
            break
          }
          case '%': {
            discount = row.rate * (discount / 100)
            total -= discount
            break
          }
        }
      }

      const billingFrequency = this.billingFrequency[row.billing_frequency]

      return `${total.toFixed(2)} / ${billingFrequency.toLowerCase()}`
    }
  }
}
</script>
