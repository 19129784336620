var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('RDataTable',{attrs:{"items":_vm.subscriptions,"fields":_vm.columns,"table-props":_vm.tableProps},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('RWidgetStatus',{attrs:{"status":item.status}})],1)]}},{key:"start_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDateString(item.start_date))+" ")])]}},{key:"end_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDateString(item.end_date))+" ")])]}},{key:"rate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" $"+_vm._s(_vm.getRateValue(item))+" ")])]}},{key:"discount",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getDiscountValue(item))+" ")])]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" $"+_vm._s(_vm.getTotalValue(item))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }